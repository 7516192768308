#public-appointment {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#public-appointment .custom-card {
  border-radius: 10px;
  background: white;
  height: 500px;
  width: 30%;
  min-width: 350px;
  padding: 1.5rem;
  overflow: hidden;
}

#public-appointment .custom-card .info-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
}


#public-appointment .custom-card .info-container .button-container {
  margin: auto -1.5rem -1.5rem;
  width: calc( 100% + 3rem );
}
