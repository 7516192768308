.main-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  perspective: 100vh;
  perspective-origin: center;
  z-index: -1;
}

.slidecontainer {
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-content .cube {
  position: relative;
  width: 100vh;
  height: 100vh;
  transform-style: preserve-3d;
  transition: all 0.8s ease-in;
}

.main-content .cube .panel {
  position: absolute;
  width: 100vh;
  height: 100vh;
  filter: blur(30px);
  /* border: 1px solid black; */
  /* background: rgba( 255, 255, 255, 0.40 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}

.main-content .cube div:nth-child(1) {
  /* background-color: red; */
  transform: rotate3d(0, 1, 0, 0deg) translateZ(50vh);
}
.main-content div:nth-child(2) {
  /* background-color: green; */
  transform: rotate3d(0, 1, 0, -90deg) translateZ(50vh);
}
.main-content div:nth-child(3) {
  /* background-color: blue; */
  transform: translateZ(-50vh);
}
.main-content div:nth-child(4) {
  /* background-color: yellow; */
  transform: rotate3d(0, 1, 0, 90deg) translateZ(50vh);
}

.main-content img {
  width: 100%;
  height: 100%;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.main-content-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content-1 .panel {
  backgroundColor: red;
  width: 50%;
  height: 50%
}

.main-content-1 .panel::before {
  content: 'test';
  background-color: white;
  width: 50vw;
  height: 50vh;
  transform: translateX(2px);
}
