.schedule-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: hsl(0,0%,97%);
  border: 1px solid #dadada;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  padding: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in;
  background: rgba( 255, 255, 255, 0.40 );
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  box-shadow: 6px 6px 6px 0 rgb(0 0 0 / 10%);
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.schedule-item-container .schedule-cap {
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.schedule-item-container .schedule-controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-item-container .info-container {
  display: flex;
  min-height: 175px;
}

.schedule-item-container .info-container img {
  position: absolute;
  height: 150px;
  right: 20px;
}

.schedule-item-container .schedule-actions-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.schedule-item-container .schedule-actions-container .divider {
  position: absolute;
  height: 1px;
  background-color: #dadada;
  width: calc( 100% + 24px );
  left: -12px;
  top: 0;
}

.schedule-cap-light {
  left: -100% !important;
  z-index: 20;
  transition: 0.3s ease-in;
}

.schedule-item-container:hover .schedule-cap-light {
  left: 0 !important;
}

.schedule-item-container:hover {
  background-color: #ffffff;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
}
