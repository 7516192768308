.calendar {

}

.calendar .calendar-header {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}

.calendar .calendar-header .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 4px;
}

.calendar .calendar-header .header-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar .calendar-header .header-buttons button {
  background-color: white;
  outline: none;
  border: none;
  cursor: pointer;
  height: 38px;
  width: 38px;
  border-radius: 19px;
  transition: all 0.1s ease-in;
  margin: 0 6px;
}

.calendar .calendar-header .header-buttons button:hover {
  background-color: rgba(0, 162, 255, 0.15);
}

.calendar .calendar-body {

}

.calendar .calendar-body .week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 6px;
}

.calendar .calendar-body .week>div {
  text-align: center;
  font-weight: bold;
  color: #212427;
}

.calendar .calendar-body .month-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar .calendar-body .month-days .day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  text-align: center;
  margin: 4px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  background-color: rgba(8, 212, 209, 1);
  color: white;
  font-weight: bold;
}

.calendar .calendar-body .month-days .disabled {
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  color: #E4E4E4
  /* font-weight: 400; */
}

.calendar .calendar-body .month-days .selected {
  background-color: rgb(8, 212, 209);
}

.calendar .calendar-body .month-days .day:hover {
  background-color: rgba(8, 212, 209, 0.6);
}

.calendar .calendar-body .month-days .disabled:hover {
  background-color: transparent;
}

.calendar .calendar-body .month-days .day .day-text {
  font-size: 16px;
}
