#company {
  padding: 100px 0 0;
}

#company .card .company-appointment-item {
  display: flex;
  cursor: pointer;
  height: 180px;
  margin: 20px;
  padding: 20px;
  border-top: 1px solid rgba(77, 80, 85, 0.1);
  transition: all 0.1s ease;
}

#company .card .company-appointment-item:hover {
  background-color: rgba(77, 80, 85, 0.1);
}

#company .card .no-company {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
