#home {
  display: flex;
  justify-content: center;
  padding: 150px 0;
  min-height: calc(100vh - 50px);
}

#home .search-container input {
  /* padding-left: 5px; */
}
