#service {
  position: relative;
  padding: 100px 0 0;
}

#service .card .calendar-container {
  position: relative;
  display: flex;
  justify-content: center;
}

#service .card .hours-container {
  position: relative;
  overflow: auto;
  height: 100%;
  opacity: 0;
  max-height: 700px;
}

#service .card .hours-container .button-container {
  display: flex;
  justify-content: center;
}

#service .card .hours-container .button-container .hour-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  min-width: 120px;
  transition: all 0.1s ease-in;
}

#service .card .hours-container .button-container .order-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  opacity: 0;
  width: 0px;
  transition: all 0.1s ease-in;
  cursor: default;
}

#service .card .hours-container .button-container.active .order-button {
  width: 120px;
  opacity: 1;
  cursor: pointer;
  margin: 6px;
  padding: 4px 15px;
}

#service .progress-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  overflow: hidden;
}

#service .progress-container .custom-progress {
  width: 0%;
  height: 1.5px;
  background-color: #52c41a;
  transition: all 0.2s ease-in;
}


#service .spinner-container {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#service .success-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  text-align: center;
  color: #08d4d1 !important;
}

#service .success-container .info-container {
  margin-top: -10rem;
}

.custom-border-right {
  border-right: 1px solid #dee2e6;
}

#service .custom-card-body {
  display: flex;
  height: 750px;
}

@media (max-width: 580px) {
  .custom-border-right {
    border-right: none;
  }

  #service .custom-card-body {
    height: auto;
  }

  #service .card .hours-container {
    max-height: none;
  }

  #service .success-container .info-container {
    margin-top: 5rem;
  }
}

#service .info-container {
  position: relative;
}

#service .info-container .image-container {
  position: absolute;
  right: 24px;
  height: 150px;
  top: 24px;
  z-index: 0;
}

#service .info-container .image-container img {
  height: 100%;
  width: auto;
}
