#header {
  position: fixed;
  top: 0;
  height: 52px;
  width: 100%;
  max-width: 100vw;
  /* background: inherit; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;

}

.glass-header::before {
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
}

#header .container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.ant-modal-body {
  overflow: hidden;
}

.forms-container {
  display: flex;
  width: calc( 300% + 72px );
  transition: all 0.3s ease-in;
}

.forms-container .form-container {
  flex: 1;
  padding-right: 24px;
  padding-left: 24px;
}

.forms-container .form-container .input {
  margin-bottom: 12px;
}

.forms-container .form-container .action-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.forms-container .form-container .divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;
}

.forms-container .form-container .divider span {
  flex: 1;
  height: 0.5px;
  background-color: #f3f3f3;
}

.forms-container .form-container .divider p {
  font-size: 16px;
  margin: 0px 12px;
}

.forms-container .form-container input {
  padding-left: 5px !important;
}
