.company-item-container {
  position: relative;
  display: flex;
  cursor: pointer;
  border: none;
  transition: all 0.1s ease-in;
  padding: 20px;
  margin: 0.75rem 0;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 90px;
}

.company-item-container:hover {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  background-color: white;
}

.company-item-container img {
  width: auto;
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}
